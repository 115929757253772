import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import reducer, { initialState } from "./reducer";
import { StateProvider } from "./StateProvider";

// const routers = createBrowserRouter(
//   createRoutesFromElements(
//     <>
//       <Route>
//         <Route index  errorElement={<Error />} element={<App />} />
//         <Route path="/" errorElement={<Error />} element={<App />} />
//         <Route path="accounts" errorElement={<Error />} element={<Accounts />} />
//         <Route path="*" errorElement={<Error />} element={<Error />} />
//         <Route path="error" errorElement={<Error />} element={<Error />} />
//       </Route>
//     </>
//   )
// );

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer}>
      <App />
    </StateProvider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();