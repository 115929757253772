import React from 'react';
import './Accounts.css';
import { useStateValue } from "./StateProvider";

const Accounts = () => {
  const [{ user }] = useStateValue();

  const handlePasswordReset = () => {
    // Logic for password reset
    alert("Password reset link has been sent to your email.");
  };

  return (
    <div className="account-container">
      <div className="profile-section">
        {/* <img src={user?.photoUrl} alt="Profile" style={{ zIndex: 2 }}className="profile-picture" /> */}
        <img src="Workout Buddy Logo.png" alt="Profile" style={{ zIndex: 0 }} className="profile-picture" />
        <h1>{user?.displayName}</h1>
        <h6>Email: {user?.email}</h6>
      </div>
      <div className="details-section">
        <div className="card">
          <h3>Account Details</h3>
          <h4><strong>Name:</strong> {user?.displayName}</h4>
          <h4><strong>Email:</strong> {user?.email}</h4>
        </div>
        <div className="card">
          <h3>Settings</h3>
          <button onClick={handlePasswordReset} className="reset-button">Reset Password</button>
        </div>
      </div>
    </div>
  );
};

export default Accounts;