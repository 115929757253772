import React from "react";
import { Link, Outlet } from "react-router-dom";
import { useStateValue } from "./StateProvider";
import { auth } from "./firebase";


function Navbar() {
  // eslint-disable-next-line
  const [{ user }, dispatch] = useStateValue();

  const handleAuthenticaton = () => {
    if (user) {
      auth.signOut();
    }
  };
  return (
    <>
      <nav className="navbar shadow-sm">
        <Link className="nav-items" to="/"><h3 className="nav-items">Workout Buddy <img alt="" width="15%" src="Workout Buddy Logo.png"></img></h3></Link>
        <div className="nav-items">
          <div class="dropdown">
            <button class="nav-item nav-logout dropdown-button"><span class="material-symbols-outlined">account_circle</span>{user?.displayName}</button> 
            <div class="dropdown-content">
              {/* <span class="material-symbols-outlined">account_circle</span> */}
              <Link class="nav-item nav-logout" to="/accounts"><h7>Account</h7></Link>
              <hr />
              <Link class="nav-item nav-logout" to="/workout"><h7>Workout</h7></Link>
              <hr />
              <Link class="nav-item nav-logout" onClick={handleAuthenticaton}><h7>Logout</h7></Link>
            </div>
          </div>
        </div>
      </nav>
      <main>
        <Outlet />
      </main>
    </>
  );
}

export default Navbar;