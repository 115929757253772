import React, { useEffect, useState } from "react";
import "./App.css";
import Home from "./Home";
import FirebaseLogin from "./FirebaseLogin";
import Accounts from "./Accounts";
import Navbar from "./Navbar";
import Error from "./Error/Error404";
import Protected from "./Protected";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useStateValue } from "./StateProvider";
import { auth } from "./firebase";
import Content from "./Content";

function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  // eslint-disable-next-line
  const [{}, dispatch] = useStateValue();

  useEffect(() => {
    // will only run once when the app component loads...

    auth.onAuthStateChanged((authUser) => {
      console.log(authUser);

      if (authUser) {
        // the user just logged in / the user was logged in

        dispatch({
          type: "SET_USER",
          user: authUser,
        });
        setIsSignedIn(true);
      } else {
        // the user is logged out
        dispatch({
          type: "SET_USER",
          user: null,
        });
        setIsSignedIn(false);
      }
    });
    // eslint-disable-next-line
  }, []);
  return (
    <Router>
      {/* {isSignedIn ? (
        <div className="d-grid mt-5">
          <button className="btn-danger" onClick={signout}>
            Sign out
          </button>
        </div>
      ) : (
        <div className="d-grid mt-5">
          <button className="btn-dark" onClick={signin}>
            Sign in
          </button>
        </div>
      )} */}
      <div className="app">
        <Routes>
          <Route
            exact
            path="/login"
            element={
              <div>
                <FirebaseLogin />
              </div>
            }
          />
          <Route
            exact
            path="/"
            element={
              <Protected isSignedIn={isSignedIn}>
                <div>
                  <Navbar />
                  <Home />
                </div>
              </Protected>
            }
          />
          <Route
            exact
            path="/workout"
            element={
              <Protected isSignedIn={isSignedIn}>
                <div>
                  <Navbar />
                  <Content />
                </div>
              </Protected>
            }
          />
          <Route
            exact
            path="/accounts"
            element={
              <Protected isSignedIn={isSignedIn}>
                <div>
                  <Navbar />
                  <Accounts />
                </div>
              </Protected>
            }
          />
          <Route
            exact
            path="*"
            element={
              <div>
                <Error />
              </div>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;